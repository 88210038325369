import React from "react";
import { Link, Outlet } from "react-router-dom";

const Navbar = ({ accessToken }) => {
  return (
    <>
      <nav className="bg-black py-4 mb-4 h-fit">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between">
            <div className="flex items-center flex-col sm:flex-row">
              <Link
                to={`/?access_token=${accessToken}`}
                className="text-cyan-400 hover:bg-cyan-50 hover:text-cyan-700 px-3 py-2 rounded-md text-sm font-medium"
              >
                <div className="flex-shrink-0 font-bold text-3xl">
                  {/* <img
                  className="h-8 w-8"
                  src="https://tailwindui.com/img/logos/workflow-mark-indigo-300.svg"
                  alt="Logo"
                /> */}
                  Variety Heaven
                </div>
              </Link>
              <div className="">
                <div className="ml-10 flex-col sm:flex-row flex items-baseline space-x-4">
                  {/* <Link
                    to={`/single?access_token=${accessToken}`}
                    className="text-gray-300 hover:bg-cyan-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                  >
                    Single Product
                  </Link> */}
                  <Link
                    to={`/variationListing?access_token=${accessToken}`}
                    className="text-gray-300 hover:bg-cyan-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                  >
                    Product with Varients
                  </Link>
                  <Link
                    to={`/orders?access_token=${accessToken}`}
                    className="text-gray-300 hover:bg-cyan-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                  >
                    Orders
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <Outlet />
    </>
  );
};

export default Navbar;
