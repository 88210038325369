import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import Uppy from "@uppy/core";
import { Dashboard } from "@uppy/react";
import XHRUpload from "@uppy/xhr-upload";

import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import FileUploader from "./FileUploader";

const ProductDetail = ({ accessToken }) => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [product, setProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const createUppyInstanceForVideo = (videoIndex) => {
    return new Uppy({
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: [".mp4"],
        maxFileSize: 52428800,
      },
    }).use(XHRUpload, {
      // endpoint: `https://vh-apis.onrender.com/uploadVideo?videoIndex=${videoIndex}&id=${id}`,
      endpoint: `https://vh-apis.vercel.app/uploadVideo?videoIndex=${videoIndex}&id=${id}`,
      // endpoint: `http://127.0.0.1:3005/uploadVideo?videoIndex=${videoIndex}&id=${id}`,
      formData: true,
      bundle: true,
      fieldName: "productVideo",
    });
  };

  const [uppyInstancesForVideos] = useState([
    createUppyInstanceForVideo(1),
    createUppyInstanceForVideo(2),
  ]);

  const [images] = useState(() =>
    new Uppy({
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: [".jpeg", ".jpg", ".png"],
      },
    }).use(XHRUpload, {
      // endpoint: `https://vh-apis.onrender.com/addImages?id=${id}`,
      endpoint: `https://vh-apis.vercel.app/addImages?id=${id}`,
      formData: true,
      bundle: true,
      fieldName: "images",
    })
  );

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await fetch(
          // `https://vh-apis.onrender.com/getProduct?id=${id}`
          `https://vh-apis.vercel.app/getProduct?id=${id}`
        );
        const data = await response.json();
        console.log(data.product[0]);
        setProduct(data.product[0]);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <div>
      <div className="m-8 flex gap-10 flex-col lg:flex-row">
        <div className="border bottom-1 p-3 border-black">
          <img src={product.thumbnail} alt="" />
        </div>
        <div>
          <h2 className="text-xl font-bold">Title: {product.title}</h2>
          <p className="text-lg mt-2">
            <span>Description</span>: {product.description}
          </p>
          <div className="flex gap-5">
            <p className="text-lg mt-2">Price: ₹ {product.price}</p>
            <p className="text-lg mt-2">
              Discounted Price: ₹ {product.discountedPrice}
            </p>
          </div>
          <div className="flex gap-5">
            <p className="text-lg mt-2">SKU: {product.sku}</p>
            <p className="text-lg mt-2">GST rate: {product.gstRate}%</p>
          </div>
        </div>
      </div>

      <div className="mt-3 flex flex-col lg:flex-row w-full justify-around">
        <div className="w-full lg:mx-10">
          <h1 className="font-bold text-3xl text-slate-800 text-center my-3">
            VARIATIONS
          </h1>
          <ol className="flex flex-col gap-5 w-full">
            {product.variations.map((variant, index) => (
              <li
                key={variant._id}
                className="border-2 border-blue-500 w-full"
              >
                <details className="">
                  <summary className="cursor-pointer text-lg px-10 py-2 bg-gray-200 font-semibold flex w-full gap-5">
                    <span className="self-center">{index + 1}</span>
                    <div className="flex justify-between w-full flex-col lg:flex-row gap-4">
                      <span className="flex gap-10">
                        <img
                          src={variant.images[0]}
                          alt="No Thumbnail Set"
                          className="h-24 self-center"
                        />
                        <span className="self-center">{variant.color}</span>
                      </span>
                      <span className="self-center">
                        SKU: {variant.sku.toUpperCase()}
                      </span>
                    </div>
                  </summary>
                  <div>
                    <div>
                      <FileUploader varient={variant} pId={product._id} />
                    </div>
                  </div>
                </details>
              </li>
            ))}
          </ol>
        </div>

        <div className="mt-20">
          <h1 className="text-3xl font-bold mb-6 text-center">Upload Videos</h1>
          <div className="mt-10 gap-3 w-fit justify-around flex flex-col mx-auto">
            {uppyInstancesForVideos.map((uppy, index) => (
              <div
                key={index}
                className="bg-cyan-50 rounded-lg shadow-2xl overflow-hidden hover:shadow-xl flex flex-col justify-center items-center"
              >
                <div>
                  <iframe
                    src={product[`video${index + 1}`]}
                    allowFullScreen={true}
                    height="180px"
                  ></iframe>
                </div>
                <div className="p-4">
                  <Dashboard
                    uppy={uppy}
                    proudlyDisplayPoweredByUppy={false}
                    theme="dark"
                    width={300}
                    height={170}
                  />
                  <p className="text-lg font-semibold text-center mt-4">
                    Video {index + 1}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
