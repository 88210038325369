import React, { useState, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Navbar from "./Navbar";
import Home from "./Home";
import AddThumbnail from "./AddThumbnail";
import ProductDetail from "./ProductDetail";
import Login from "./Login";
import SingleProductUploader from "./SingleProductUploader";
import Orders from "./Orders";
import OrderDetails from "./OrderDetails";
import VariationListing from "./VariationListing";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false); 

  const allowedPhoneNumbers = ["9512467691", "9898437599", "8160185875", "9624152754"];
  
  const searchParams = new URLSearchParams(window.location.search);
  const accessToken = searchParams.get("access_token");
  useEffect(() => {

    // console.log(isAuthenticated)
    // console.log(accessToken)
    
    console.log("allowedPhoneNumbers.includes(localStorage.getItem('phone'))")
    console.log(allowedPhoneNumbers.includes(localStorage.getItem("phone")))
    console.log(typeof(localStorage.getItem("phone")))
    
    if (allowedPhoneNumbers.includes(localStorage.getItem("phone"))) {
      setIsAuthenticated(true);

    }

  }, []);

  document.title = "Admin Variety Heaven"
  return (
    <Routes>
      {/* Render the Navbar regardless of authentication status */}
      <Route path="/" element={<Navbar accessToken={accessToken}/>} >

      {/* Render different content based on authentication status */}
      {/* <Route path="/login" element={<Login />} /> */}
      {!isAuthenticated ? (
        // Render the Login component if not authenticated
        <Route path="" element={<Login/>} />
      ) : (
        // Render the main application content if authenticated
        <>
          <Route path="" element={<Home accessToken={accessToken}/>} />
          <Route path="single" element={<SingleProductUploader accessToken={accessToken}/>} />
          <Route path="addThumbnail" element={<AddThumbnail accessToken={accessToken}/>} />
          <Route path="productDetail" element={<ProductDetail accessToken={accessToken}/>} />
          <Route path="orders" element={<Orders  accessToken={accessToken}/>} />
          <Route path="orderDetails" element={<OrderDetails  accessToken={accessToken}/>} />
          <Route path="variationListing" element={<VariationListing  accessToken={accessToken}/>} />
        </>
      )}

      </Route>
    </Routes>
  );
};

export default App;
