import React, { useState } from "react";
import Uppy from "@uppy/core";
import { Dashboard } from "@uppy/react";
import XHRUpload from "@uppy/xhr-upload";
import { useSearchParams } from "react-router-dom";

import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";

const FileUploader = ({ varient, pId }) => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const createUppyInstance = (imageIndex) => {
    return new Uppy({
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: [".jpg", ".jpeg", ".png"],
      },
    }).use(XHRUpload, {
      // endpoint: `https://vh-apis.onrender.com/addImages?imageIndex=${imageIndex}&id=${id}`,
      // endpoint: `https://vh-apis.onrender.com/addVariationListing/addImageToVarient?imageIndex=${imageIndex}&vId=${varient._id}&pId=${pId}`,
      endpoint: `https://vh-apis.vercel.app/addVariationListing/addImageToVarient?imageIndex=${imageIndex}&vId=${varient._id}&pId=${pId}`,
      formData: true,
      bundle: true,
      fieldName: "image",
    });
  };

  

  const [uppyInstances] = useState([
    createUppyInstance(0),
    createUppyInstance(1),
    createUppyInstance(2),
    createUppyInstance(3),
    createUppyInstance(4),
  ]);

  

  return (
    <div className="container py-8 mx-3">
      <div>
        <h1 className="text-3xl font-bold mb-6 text-center">
          Upload varient Images
        </h1>
        <div className="grid grid-cols-1 gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 justify-center">
          {uppyInstances.map((uppy, index) => (
            <div
              key={index}
              className="bg-cyan-50 rounded-lg shadow-2xl overflow-hidden hover:shadow-xl flex flex-col justify-center items-center"
            >
              <img
                src={varient.images[index]}
                alt={`varient Image ${index + 1}`}
                className="h-48 w-auto"
              />
              <div className="p-4">
                <Dashboard
                  uppy={uppy}
                  proudlyDisplayPoweredByUppy={false}
                  theme="dark"
                  width={300}
                  height={170}
                />
                <p className="text-lg font-semibold text-center mt-4">
                  Image {index + 1}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      
    </div>
  );
};

export default FileUploader;
