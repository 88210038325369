import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const VariationListing = ({ accessToken }) => {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [discountedPrice, setDiscountedPrice] = useState("");
  const [category, setCategory] = useState("");
  const [sku, setSku] = useState("");
  const [hsn, setHsn] = useState("");
  const [categories, setCategories] = useState([]);
  const [colors, setColors] = useState([""]);
  const [bulletPoints, setBulletPoints] = useState([""]);

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };

  const handleDiscountedPriceChange = (event) => {
    setDiscountedPrice(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleSkuChange = (e) => {
    setSku(e.target.value);
  };

  const handleHsnChange = (e) => {
    setHsn(e.target.value);
  };

  const handleColorChange = (index, event) => {
    const newColors = [...colors];
    newColors[index] = event.target.value;
    setColors(newColors);
  };

  const handleAddColor = () => {
    setColors([...colors, ""]);
  };

  const handleRemoveColor = (index) => {
    const newColors = [...colors];
    newColors.splice(index, 1);
    setColors(newColors);
  };

  const handleBulletPointChange = (index, event) => {
    const newBulletPoints = [...bulletPoints];
    newBulletPoints[index] = event.target.value;
    setBulletPoints(newBulletPoints);
  };

  const handleAddBulletPoint = () => {
    setBulletPoints([...bulletPoints, ""]);
  };

  const handleRemoveBulletPoint = (index) => {
    const newBulletPoints = [...bulletPoints];
    newBulletPoints.splice(index, 1);
    setBulletPoints(newBulletPoints);
  };

  const handleAddCategory = async () => {
    const categoryToAdd = prompt("Enter category to add");
    if (categoryToAdd) {
      try {
        const res = await fetch(
          `https://vh-apis.onrender.com/addCatagory?catagory=${categoryToAdd}`
        );
        const data = await res.json();
        console.log(data.catagories)
        setCategories(data.catagories);
      } catch (error) {
        alert("Failed to add category");
      }
    } else {
      alert("Category addition failed");
    }
  };

  const fetchCategories = async () => {
    try {
      const res = await fetch(
        // "https://vh-apis.onrender.com/addCatagory/getCatagories"
        "https://vh-apis.vercel.app/addCatagory/getCatagories"
      );
      if (!res.ok) {
        throw new Error("Failed to fetch categories");
      }
      const data = await res.json();
      console.log(data);
      return data.catagories;
    } catch (error) {
      console.error("Error fetching categories:", error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchCategories();
        setCategories(response);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Form validation
    // if (title.trim().length < 15) {
    //   alert("Title should be at least 15 characters long.");
    //   return;
    // }

    // if (description.trim().split(/\s+/).length < 30) {
    //   alert("Description should be at least 30 words long.");
    //   return;
    // }

    if (!price || !discountedPrice || !category) {
      alert("All fields are required.");
      return;
    }

    console.log("SUBMITTING...");

    try {
      const dataToSend = {
        title: title,
        description: description,
        price: price,
        discountedPrice: discountedPrice,
        category: category,
        sku: sku,
        hsn: hsn,
        colors: colors,
        bulletPoints: bulletPoints,
      };

      // const url = "https://vh-apis.onrender.com/addVariationListing";
      const url = "https://vh-apis.vercel.app/addVariationListing";
      // const url = "https://vh-apis.onrender.com/payment/createOrder";

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Specify the content type as JSON
        },
        body: JSON.stringify(dataToSend), // Convert the data to JSON string
      };

      fetch(url, options)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json(); // Parse the JSON response
        })
        .then((data) => {
          console.log("Product uploaded successfully:", data);

          const { _id } = data.product;

          // const redirectUrl = `https://admin.varietyheaven.in/addThumbnail?id=${_id}&access_token=${accessToken}`;
          const redirectUrl = `https://admin.varietyheaven.in/addThumbnail?id=${_id}&access_token=${accessToken}`;

          window.location.href = redirectUrl;
        })
        .catch((error) => {
          console.error("Error creating order:", error);
          // Handle errors
        });
    } catch (error) {
      console.error("Error uploading product:", error);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <form
        onSubmit={handleSubmit}
        className="bg-white shadow-lg rounded-lg p-8 w-full max-w-4xl"
      >
        <h1 className="text-center font-bold text-3xl mb-10">
          VARIATION LISTING
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="flex flex-col gap-4">
            <div>
              <label
                htmlFor="title"
                className="block font-medium mb-2 text-gray-700"
              >
                Title
              </label>
              <input
                required
                type="text"
                id="title"
                value={title}
                onChange={handleTitleChange}
                className="border border-gray-300 rounded-md py-3 px-4 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
            </div>

            <div>
              <label
                htmlFor="sku"
                className="block font-medium mb-2 text-gray-700"
              >
                SKU
              </label>
              <input
                id="sku"
                value={sku}
                onChange={handleSkuChange}
                className="border border-gray-300 rounded-md py-3 px-4 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
                rows="3"
              />
            </div>

            <div>
              <label
                htmlFor="hsn"
                className="block font-medium mb-2 text-gray-700"
              >
                HSN
              </label>
              <input
                id="hsn"
                value={hsn}
                onChange={handleHsnChange}
                className="border border-gray-300 rounded-md py-3 px-4 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
                rows="3"
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="description"
              className="block font-medium mb-2 text-gray-700"
            >
              Description
            </label>
            <textarea
              id="description"
              value={description}
              onChange={handleDescriptionChange}
              className="border border-gray-300 rounded-md py-3 px-4 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
              rows="3"
            ></textarea>
          </div>

          <div>
            <label
              htmlFor="price"
              className="block font-medium mb-2 text-gray-700"
            >
              Original Price (in rupees)
            </label>
            <input
              type="number"
              id="price"
              value={price}
              onChange={handlePriceChange}
              className="border border-gray-300 rounded-md py-3 px-4 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
          </div>
          <div>
            <label
              htmlFor="discountedPrice"
              className="block font-medium mb-2 text-gray-700"
            >
              Discounted Price (in rupees)
            </label>
            <input
              type="number"
              id="discountedPrice"
              value={discountedPrice}
              onChange={handleDiscountedPriceChange}
              className="border border-gray-300 rounded-md py-3 px-4 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
          </div>
          <div>
            <label
              htmlFor="category"
              className="block font-medium mb-2 text-gray-700"
            >
              Category
            </label>
            <select
              id="category"
              value={category}
              onChange={handleCategoryChange}
              className="border border-gray-300 rounded-md py-3 px-4 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              <option value="" disabled>
                Select Category
              </option>
              {categories &&
                categories.map((cat) => (
                  <option key={cat} value={cat}>
                    {cat}
                  </option>
                ))}
            </select>

            <button
              className="bg-green-600 px-3 py-2 my-3 rounded-lg font-semibold text-white hover:bg-green-800"
              type="button"
              onClick={handleAddCategory}
            >
              Add Category
            </button>
          </div>

          <div>
            <label
              htmlFor="colors"
              className="block font-medium mb-2 text-gray-700"
            >
              Colors
            </label>
            {colors.map((color, index) => (
              <div key={index} className="flex items-center gap-4 mb-2">
                <input
                  type="text"
                  value={color}
                  onChange={(event) => handleColorChange(index, event)}
                  className="border border-gray-300 rounded-md py-2 px-3 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
                <button
                  type="button"
                  onClick={() => handleRemoveColor(index)}
                  className="  text-white px-2 py-1 rounded-md "
                >
                  <img
                    src="https://png.pngtree.com/png-clipart/20230527/original/pngtree-red-cross-paint-clipart-transparent-background-png-image_9171931.png"
                    className="h-10"
                    alt="Remove"
                  />
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={handleAddColor}
              className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-700"
            >
              Add Color
            </button>
          </div>

          <div>
            <label
              htmlFor="bulletPoints"
              className="block font-medium mb-2 text-gray-700"
            >
              Bullet Points
            </label>
            {bulletPoints.map((bullet, index) => (
              <div key={index} className="flex items-center gap-4 mb-2">
                <input
                  type="text"
                  value={bullet}
                  onChange={(event) => handleBulletPointChange(index, event)}
                  className="border border-gray-300 rounded-md py-2 px-3 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
                <button
                  type="button"
                  onClick={() => handleRemoveBulletPoint(index)}
                  className="  text-white px-2 py-1 rounded-md "
                >
                  <img
                    src="https://png.pngtree.com/png-clipart/20230527/original/pngtree-red-cross-paint-clipart-transparent-background-png-image_9171931.png"
                    className="h-10"
                    alt="Remove"
                  />
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={handleAddBulletPoint}
              className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-700"
            >
              Add Bullet Point
            </button>
          </div>
        </div>
        <div className="mt-8 text-center">
          <button
            type="submit"
            className="bg-indigo-500 hover:bg-indigo-600 text-white py-3 px-6 rounded-md shadow-md"
          >
            Submit & Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default VariationListing;
