import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import AddThumbnail from "./AddThumbnail";

const Home = ({ accessToken }) => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const deleteProduct = async (id) => {
    const isOK = window.confirm("ARE YOU SURE.");
    if (isOK) {
      try {
        const response = await fetch(
          // `https://vh-apis.onrender.com/deleteProduct?id=${id}`
          `https://vh-apis.vercel.app/deleteProduct?id=${id}`
          // `http://127.0.0.1:3005/deleteProduct?id=${id}`
        );

        // Fetch updated products data after successful deletion
        fetchProducts();
      } catch (err) {
        setError(err.message);
      }
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await fetch(
        // "https://vh-apis.onrender.com/getAllProducts"
        "https://vh-apis.vercel.app/getAllProducts"
        // "http://127.0.0.1:3005/getAllProducts"
      );
      const data = await response.json();
      setProducts(data.allProducts);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const formatColors = (variations) => {
    const colors = variations.map(variation => variation.color);
    return colors.join(', ');
  };

  if (isLoading) {
    return <div className="text-3xl font-bold">Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="container mx-auto py-8 overflow-scroll">
      <h1 className="text-3xl font-bold mb-6">Products</h1>
      <div className="overflow-x-auto">
        <table
          className="table-auto border-separate border-spacing-y-3 border-black"
          style={{ tableLayout: "fixed" }}
        >
          <thead>
            <tr className="bg-gray-200">
              <th className="px-4 py-2">Thumbnail</th>
              <th className="px-4 py-2">Title</th>
              <th className="px-4 py-2">Variations</th>
              <th className="px-4 py-2">Description</th>
              <th className="px-4 py-2">Price</th>
              <th className="px-4 py-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product) => (
              <tr
                key={product._id}
                className="border-b hover:bg-cyan-50 cursor-pointer"
              >
                <td className="px-4 py-2" style={{ width: "20%" }}>
                  {product.thumbnail ? (
                    <div className="w-full text-center flex flex-col item-center">
                      <img
                        src={product.thumbnail}
                        alt={product.title}
                        className="h-40 w-fit object-cover self-center"
                      />

                      <Link
                        to={`/addThumbnail?id=${product._id}&title=${product.title}&access_token=${accessToken}`}
                      >
                        <button className="bg-cyan-700 p-1 text-md mt-1 w-full rounded-md text-cyan-100 hover:bg-cyan-900">
                          Update
                        </button>
                      </Link>
                    </div>
                  ) : (
                    <div className="w-full text-center flex flex-col item-center">
                      <Link
                        to={`/addThumbnail?id=${product._id}&title=${product.title}&access_token=${accessToken}`}
                      >
                        <button className="bg-cyan-700 p-3 text-sm mt-3 w-full rounded-md text-cyan-100 hover:bg-cyan-900">
                          ADD THUMBNAIL
                        </button>
                      </Link>
                    </div>
                  )}
                </td>
                <td className="px-4 py-2" style={{ width: "20%" }}>
                  <div className="flex flex-col gap-2">
                    <span className="line w-40 line-clamp-2">Category: {product.category}</span>
                    <span className="line w-40 line-clamp-2">{product.title}</span>
                    <span className="line w-40 font-semibold">SKU: {product.sku}</span>
                    <span className="line w-40">HSN: {product.hsn}</span>
                    <span className="text-sm text-gray-500">
                      {product._id}
                    </span>
                  </div>
                </td>
                <td className="px-4 py-2" style={{ width: "20%" }}>
                  <div className="flex flex-col gap-2">
                    <span className="line w-40">Number of variants: {product.variations.length}</span>
                    <span className="line w-40">Colors: {formatColors(product.variations)}</span>
                  </div>
                </td>
                <td className="px-4 py-2 line-clamp-5 h-32">
                  {product.description}
                </td>
                <td className="px-4 py-2" style={{ width: "10%" }}>
                  <div className="flex flex-col">
                    <span>₹ {product.discountedPrice}</span>
                    <strike>₹ {product.price}</strike>
                  </div>
                </td>
                <td className="px-4 py-2" style={{ width: "20%" }}>
                  <div className="flex gap-2">
                    <Link to={`/productDetail?id=${product._id}&access_token=${accessToken}`}>
                      <button className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded">
                        View Details
                      </button>
                    </Link>

                    <button
                      onClick={() => deleteProduct(product._id)}
                      className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded"
                    >
                      delete
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Home;
