// import React, { useState, useEffect } from "react";
// import { useSearchParams } from "react-router-dom";

// const OrderDetails = () => {
//   const [order, setOrder] = useState(null);
//   const [isLoading, setIsLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [searchParams] = new useSearchParams();
//   const id = searchParams.get("id");

//   useEffect(() => {
//     const fetchOrderDetails = async () => {
//       try {
//         const response = await fetch(
//           `http://127.0.0.1:3005/orders/getOrder?id=${id}`
//         );
//         if (!response.ok) {
//           throw new Error("Failed to fetch order details");
//         }
//         const data = await response.json();
//         setOrder(data.order);
//         setIsLoading(false);
//       } catch (error) {
//         setError(error.message);
//         setIsLoading(false);
//       }
//     };

//     fetchOrderDetails();
//   }, []);

//   if (isLoading) {
//     return <div className="text-center mt-4">Loading...</div>;
//   }

//   if (error) {
//     return <div className="text-center mt-4 text-red-500">Error: {error}</div>;
//   }

//   return (
//     <div className="container mx-auto p-4">
//       {order && (
//         <div>
//           <h2 className="text-2xl font-bold mb-4">Order Details</h2>
//           <div className="grid lg:grid-cols-2 md:grid-cols-1 gap-4">
//             <div className="bg-gray-100 p-4 rounded-md">
//               <p className="text-lg">
//                 <span className="font-semibold">Order ID:</span> {order._id}
//               </p>
//               <p className="text-lg">
//                 <span className="font-semibold">Order Status:</span>{" "}
//                 {order.orderDetails.order_status}
//               </p>
//               <p className="text-lg">
//                 <span className="font-semibold">Order Amount:</span> ₹
//                 {order.orderDetails.order_amount}
//               </p>
//               <p className="text-lg">
//                 <span className="font-semibold">Order Currency:</span>{" "}
//                 {order.orderDetails.order_currency}
//               </p>
//               <p className="text-lg">
//                 <span className="font-semibold">Order Expiry Time:</span>{" "}
//                 {order.orderDetails.order_expiry_time}
//               </p>
//               <p className="text-lg">
//                 <span className="font-semibold">Customer Phone:</span>{" "}
//                 {order.orderDetails.customer_details.customer_phone}
//               </p>
//               <div className="mt-4">
//                 <h3 className="text-xl font-semibold">Customer Details</h3>
//                 <p>
//                   <span className="font-semibold">Customer Name:</span>{" "}
//                   {order.orderDetails.customer_details.customer_name}
//                 </p>
//                 <p>
//                   <span className="font-semibold">Customer Email:</span>{" "}
//                   {order.orderDetails.customer_details.customer_email}
//                 </p>
//               </div>
//             </div>
//             <div className="bg-gray-100 p-4 rounded-md">
//               <h3 className="text-xl font-semibold">Address</h3>
//               <p>
//                 <span className="font-semibold">Street Address:</span>{" "}
//                 {order.address.streetAddress}
//               </p>
//               <p>
//                 <span className="font-semibold">Apt/Suite:</span>{" "}
//                 {order.address.aptSuite}
//               </p>
//               <p>
//                 <span className="font-semibold">Floor:</span>{" "}
//                 {order.address.floor}
//               </p>
//               <p>
//                 <span className="font-semibold">Building:</span>{" "}
//                 {order.address.building}
//               </p>
//               <p>
//                 <span className="font-semibold">Landmark:</span>{" "}
//                 {order.address.landmark}
//               </p>
//               <p>
//                 <span className="font-semibold">City:</span>{" "}
//                 {order.address.city}
//               </p>
//               <p>
//                 <span className="font-semibold">State:</span>{" "}
//                 {order.address.state}
//               </p>
//               <p>
//                 <span className="font-semibold">Zip:</span> {order.address.zip}
//               </p>
//             </div>
//           </div>
//           <div className="bg-gray-100 p-4 rounded-md mt-4">
//             <h3 className="text-xl font-semibold">Product Details</h3>
//             <div className="flex gap-5 mt-4">
//               <p>
//                 <img
//                   src={order.productDetails.thumbnail}
//                   alt=""
//                   className="h-36"
//                 />
//               </p>
//               <div>
//                 <p>
//                   <span className="font-semibold">Title:</span>{" "}
//                   {order.productDetails.title}
//                 </p>
//                 <p>
//                   <span className="font-semibold">Price:</span> ₹
//                   {order.productDetails.price}
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default OrderDetails;

import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const OrderDetails = () => {
  const [order, setOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchParams] = new useSearchParams();
  const id = searchParams.get("id");
  const [formData, setFormData] = useState({
    status: "",
    trackingNumber: "",
    courierProvider: "",
  });
  const [isDataChanged, setIsDataChanged] = useState(false)

  const handleChange = (event) => {
    setIsDataChanged(true)
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await fetch(
          // `https://vh-apis.onrender.com/orders/getOrder?id=${id}`
          `https://vh-apis.vercel.app/orders/getOrder?id=${id}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch order details");
        }
        const data = await response.json();
        setOrder(data.order);
        setFormData({
          status: data.order.orderStatus,
          trackingNumber: data.order.shipmentDetails.trackingId,
          courierProvider: data.order.shipmentDetails.shippingProvider
        })
        setIsLoading(false);
      } catch (error) {
        setError(error.message);
        setIsLoading(false);
      }
    };

    fetchOrderDetails();
  }, []);

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);

    // const resp = await fetch(`https://vh-apis.onrender.com/orders/updateOrder?id=${id}&orderStatus=${formData.status}&shippingProvider=${formData.courierProvider}&trackingId=${formData.trackingNumber}`)
    const resp = await fetch(`https://vh-apis.vercel.app/orders/updateOrder?id=${id}&orderStatus=${formData.status}&shippingProvider=${formData.courierProvider}&trackingId=${formData.trackingNumber}`)
    // const resp = await fetch(`http://127.0.0.1:3005/orders/updateOrder?id=${id}&orderStatus=${formData.status}&shippingProvider=${formData.courierProvider}&trackingId=${formData.trackingNumber}`)

    const data = await resp.json()

    if(data.updatedOrder){
      setIsDataChanged(false)
      alert("Order details Updated Successfully")

    }else{
      alert("Problem with updating order details")
    }

    setOrder(data.updatedOrder)
  };

  if (isLoading) {
    return <div className="text-center mt-4">Loading...</div>;
  }

  if (error) {
    return <div className="text-center mt-4 text-red-500">Error: {error}</div>;
  }

  return (
    <div className="container mx-auto p-4">
      {order && (
        <div>
          <h2 className="text-2xl font-bold mb-4">Order Details</h2>
          <div className="grid lg:grid-cols-2 md:grid-cols-1 gap-4">
            <div className="bg-gray-100 p-4 rounded-md">
              <p className="text-lg">
                <span className="font-semibold">Order ID:</span> {order._id}
              </p>
              <p className="text-lg">
                <span className="font-semibold">Order Status:</span>{" "}
                {order.orderDetails.order_status}
              </p>
              <p className="text-lg">
                <span className="font-semibold">Order Amount:</span> ₹
                {order.orderDetails.order_amount}
              </p>
              <p className="text-lg">
                <span className="font-semibold">Order Currency:</span>{" "}
                {order.orderDetails.order_currency}
              </p>
              <p className="text-lg">
                <span className="font-semibold">Order Expiry Time:</span>{" "}
                {order.orderDetails.order_expiry_time}
              </p>
              <p className="text-lg">
                <span className="font-semibold">Customer Phone:</span>{" "}
                {order.orderDetails.customer_details.customer_phone}
              </p>
              <div className="mt-4">
                <h3 className="text-xl font-semibold">Customer Details</h3>
                <p>
                  <span className="font-semibold">Customer Name:</span>{" "}
                  {order.orderDetails.customer_details.customer_name}
                </p>
                <p>
                  <span className="font-semibold">Customer Email:</span>{" "}
                  {order.orderDetails.customer_details.customer_email}
                </p>
              </div>
            </div>
            <div className="bg-gray-100 p-4 rounded-md">
              <h3 className="text-xl font-semibold">Address</h3>
              <p>
                <span className="font-semibold">Street Address:</span>{" "}
                {order.address.streetAddress}
              </p>
              <p>
                <span className="font-semibold">Apt/Suite:</span>{" "}
                {order.address.aptSuite}
              </p>
              <p>
                <span className="font-semibold">Floor:</span>{" "}
                {order.address.floor}
              </p>
              <p>
                <span className="font-semibold">Building:</span>{" "}
                {order.address.building}
              </p>
              <p>
                <span className="font-semibold">Landmark:</span>{" "}
                {order.address.landmark}
              </p>
              <p>
                <span className="font-semibold">City:</span>{" "}
                {order.address.city}
              </p>
              <p>
                <span className="font-semibold">State:</span>{" "}
                {order.address.state}
              </p>
              <p>
                <span className="font-semibold">Zip:</span> {order.address.zip}
              </p>
            </div>
          </div>

          <div className="grid lg:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-4 justify-between">
            <div className="bg-gray-100 p-4 rounded-md mt-4 w-full">
              <h3 className="text-xl font-semibold">Product Details</h3>
              <div className="flex gap-5 mt-4">
                <p>
                  <img
                    src={order.productDetails.thumbnail}
                    alt=""
                    className="h-54"
                  />
                </p>
                <div>
                  <p>
                    <span className="font-semibold">Title:</span>{" "}
                    {order.productDetails.title}
                  </p>
                  <p>
                    <span className="font-semibold">Price:</span> ₹
                    {order.productDetails.price}
                  </p>
                </div>
              </div>
            </div>

            <div
              id="updateorder"
              className="bg-gray-100 p-4 rounded-md mt-4 w-full"
            >
              <h3 className="text-xl font-semibold">Update Order</h3>
              <form onSubmit={handleSubmit} className="mt-4">
                <div className="mb-4">
                  <label htmlFor="status" className="block font-semibold mb-2">
                    Order Status
                  </label>
                  <select
                    id="status"
                    name="status"
                    value={formData.status}
                    onChange={handleChange}
                    className="border border-gray-300 rounded-md px-4 py-2 w-full"
                  >
                    <option value="">Select Status</option>
                    <option value="payment received">Payment Received</option>
                    <option value="shipped to courier provider">
                      Shipped to Courier Provider
                    </option>
                  </select>
                </div>
                
                <div className="mb-4">
                  <div>
                    <label
                      htmlFor="courierProvider"
                      className="block font-semibold mb-2"
                    >
                      Courier Provider
                    </label>
                    <select
                      id="courierProvider"
                      name="courierProvider"
                      value={formData.courierProvider}
                      onChange={handleChange}
                      className="border border-gray-300 rounded-md px-4 py-2 w-full"
                    >
                      <option value="">Select Courier Provider</option>
                      <option value="nandan">Nandan</option>
                      <option value="anjani">Anjani</option>
                      <option value="delhivery">Delhivery</option>
                      <option value="ecom">Ecom</option>
                      <option value="bluedart">Bluedart</option>
                      <option value="dtdc">DTDC</option>
                      <option value="tirupati">Tirupati</option>
                      <option value="indiaPost">India Post</option>
                    </select>
                  </div>
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="trackingNumber"
                    className="block font-semibold mb-2"
                  >
                    Tracking Number
                  </label>
                  <input
                    type="text"
                    id="trackingNumber"
                    name="trackingNumber"
                    value={formData.trackingNumber}
                    onChange={handleChange}
                    className="border border-gray-300 rounded-md px-4 py-2 w-full"
                  />
                </div>


                {/* <button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors"
                >
                  Update Order
                </button> */}
                
                <button
                disabled={!isDataChanged}
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors"
                >
                  Update Order
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderDetails;
