import React, { useEffect, useState } from "react";
import Uppy from "@uppy/core";
import { Dashboard } from "@uppy/react";
import XHRUpload from "@uppy/xhr-upload";

import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import { useSearchParams } from 'react-router-dom'

const AddThumbnail = ({accessToken}) => {
    const [searchParams] = new useSearchParams()
    const id = searchParams.get("id")
    // const title = searchParams.get("title")

    const [thumbnail] = useState(() =>
    new Uppy({
        restrictions:{
            maxNumberOfFiles:1,
            allowedFileTypes: ['.jpeg', '.jpg', '.png', '.gif']
        }
    }).use(XHRUpload, {
      // endpoint: `https://vh-apis.onrender.com/addThumbnail?id=${id}`,
      endpoint: `https://vh-apis.vercel.app/addThumbnail?id=${id}`,
      // endpoint: `https://vh-apis.onrender.com/addThumbnail?id=${id}`,
      formData: true,
      bundle: true,
      fieldName:"thumbnail"
    })
  );

  thumbnail.on("upload-success", ()=>{
    window.location.href = `https://admin.varietyheaven.in/productDetail?id=${id}&access_token=${accessToken}`
    // window.location.href = `http://127.0.0.1:3001/productDetail?id=${id}&access_token=${accessToken}`
  })


  return (
    <div>
        <h1 className="text-4xl font-bold">ADD THUMBNAIL</h1>
        {/* <h1 className="text-4xl font-bold">Title: {title}</h1> */}
        <h1 className="text-4xl font-bold">ID: {id}</h1>
        <Dashboard
        uppy={thumbnail}
        proudlyDisplayPoweredByUppy={false}
        maxNumberOfFiles={1}
        theme="dark"
        

      />
    </div>
  )
}

export default AddThumbnail