import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const SingleProductUploader = ({ accessToken }) => {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [discountedPrice, setDiscountedPrice] = useState("");
  const [category, setCategory] = useState("");
  const [sku, setSku] = useState("");
  const [hsn, setHsn] = useState("");
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState([]);
  const [preview1, setPreview1] = useState(null);
  const [preview2, setPreview2] = useState([]);
  const [catagories, setCatagories] = useState([]);

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };

  const handleDiscountedPriceChange = (event) => {
    setDiscountedPrice(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleSkuChange = (e) => {
    setSku(e.target.value);
  };

  const handleHsnChange = (e) => {
    setHsn(e.target.value);
  };

  const handleAddCatagory = async (event) => {
    const catagoryToAdd = prompt("ENTER CATAGORY TO ADD");
    // const res = await fetch(`http://127.0.0.1:3005/addCatagory?catagory=${catagoryToAdd}`)
    if (catagoryToAdd) {
      const res = await fetch(
        `https://vh-apis.onrender.com/addCatagory?catagory=${catagoryToAdd}`
      );
      const catagories = await res.json();
      console.log(catagories.catagories);
      setCatagories(catagories.catagories);
    }else{
      alert("Catagory Addition Failed")
    }

  };

  const fetchCatagories = async () => {
    try {
      const res = await fetch(
        "https://vh-apis.onrender.com/addCatagory/getCatagories"
      );
      if (!res.ok) {
        throw new Error("Failed to fetch categories");
      }
      const catagories = await res.json();
      return catagories.catagories;
    } catch (error) {
      console.error("Error fetching categories:", error);
      throw error; // Rethrow the error to be caught by the calling function
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchCatagories();
        setCatagories(response);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchData();
  }, []);

  const handleFileChange1 = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile1(selectedFile);
      const fileReader = new FileReader();
      fileReader.onload = () => {
        setPreview1(fileReader.result);
      };
      fileReader.readAsDataURL(selectedFile);
    } else {
      setFile1(null);
      setPreview1(null);
    }
  };

  const handleFileChange2 = (event) => {
    const selectedFiles = Array.from(event.target.files);
    if (selectedFiles.length > 0) {
      setFile2(selectedFiles);
      const previews = selectedFiles.map((file) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        return new Promise((resolve) => {
          fileReader.onload = () => {
            resolve(fileReader.result);
          };
        });
      });
      Promise.all(previews).then((previewData) => {
        setPreview2(previewData);
      });
    } else {
      setFile2([]);
      setPreview2([]);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Form validation
    if (title.trim().length < 15) {
      alert("Title should be at least 15 characters long.");
      return;
    }

    if (description.trim().split(/\s+/).length < 30) {
      alert("Description should be at least 30 words long.");
      return;
    }

    if (!price || !discountedPrice || !category) {
      alert("All fields are required.");
      return;
    }

    console.log("SUBMITTING...");

    try {
      const queryParams = new URLSearchParams({
        title: title,
        description: description,
        price: price,
        discountedPrice: discountedPrice,
        category: category,
        sku: sku,
        hsn: hsn,
      });

      const response = await fetch(
        `https://vh-apis.onrender.com/addProduct/single?${queryParams}`
        // `http://127.0.0.1:3005/addProduct/single?${queryParams}`
      );

      if (!response.ok) {
        throw new Error("Failed to upload product: " + response.statusText);
      }

      const data = await response.json();
      console.log("Product uploaded successfully:", data);

      const { _id, title: ptitle } = await data.product;

      const redirectUrl = `https://admin.varietyheaven.in/addThumbnail?id=${_id}&access_token=${accessToken}`;
      // const redirectUrl = `http://127.0.0.1:3001/addThumbnail?id=${_id}&access_token=${accessToken}`;

      window.location.href = redirectUrl;
    } catch (error) {
      console.error("Error uploading product:", error);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <form
        onSubmit={handleSubmit}
        className="bg-white shadow-lg rounded-lg p-8 w-full max-w-4xl"
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="flex flex-col gap-4">
            <div>
              <label
                htmlFor="title"
                className="block font-medium mb-2 text-gray-700"
              >
                Title
              </label>
              <input
                required
                type="text"
                id="title"
                value={title}
                onChange={handleTitleChange}
                className="border border-gray-300 rounded-md py-3 px-4 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
            </div>

            <div>
              <label
                htmlFor="sku"
                className="block font-medium mb-2 text-gray-700"
              >
                SKU
              </label>
              <input
                id="sku"
                value={sku}
                onChange={handleSkuChange}
                className="border border-gray-300 rounded-md py-3 px-4 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
                rows="3"
              />
            </div>

            <div>
              <label
                htmlFor="hsn"
                className="block font-medium mb-2 text-gray-700"
              >
                HSN
              </label>
              <input
                id="hsn"
                value={hsn}
                onChange={handleHsnChange}
                className="border border-gray-300 rounded-md py-3 px-4 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
                rows="3"
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="description"
              className="block font-medium mb-2 text-gray-700"
            >
              Description
            </label>
            <textarea
              id="description"
              value={description}
              onChange={handleDescriptionChange}
              className="border border-gray-300 rounded-md py-3 px-4 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
              rows="3"
              required
            ></textarea>
          </div>

          <div>
            <label
              htmlFor="price"
              className="block font-medium mb-2 text-gray-700"
            >
              Original Price (in rupees)
            </label>
            <input
              type="number"
              id="price"
              value={price}
              onChange={handlePriceChange}
              className="border border-gray-300 rounded-md py-3 px-4 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
          </div>
          <div>
            <label
              htmlFor="discountedPrice"
              className="block font-medium mb-2 text-gray-700"
            >
              Discounted Price (in rupees)
            </label>
            <input
              type="number"
              id="discountedPrice"
              value={discountedPrice}
              onChange={handleDiscountedPriceChange}
              className="border border-gray-300 rounded-md py-3 px-4 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
          </div>
          <div>
            <label
              htmlFor="category"
              className="block font-medium mb-2 text-gray-700"
            >
              Category
            </label>
            <select
              id="category"
              value={category}
              onChange={handleCategoryChange}
              className="border border-gray-300 rounded-md py-3 px-4 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              <option value="" disabled>
                Select Category
              </option>
              {catagories.map((cat) => (
                <option key={cat} value={cat}>
                  {cat}
                </option>
              ))}
            </select>

            <button
              className="bg-green-600 px-3 py-2 my-3 rounded-lg font-semibold text-white hover:bg-green-800"
              type="button"
              onClick={handleAddCatagory}
            >
              Add Catagory
            </button>
          </div>
        </div>
        <div className="mt-8 text-center">
          <button
            type="submit"
            className="bg-indigo-500 hover:bg-indigo-600 text-white py-3 px-6 rounded-md shadow-md"
          >
            Submit & Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default SingleProductUploader;
