import React, { useState, useNavigate, useEffect } from "react";
import { Link } from "react-router-dom";

const Orders = ({ accessToken }) => {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  // const navigate = useNavigate();

  const deleteProduct = async (id) => {
    const isOK = window.confirm("ARE YOU SURE.");
    if (isOK) {
      try {
        const response = await fetch(
          // `https://vh-apis.onrender.com/deleteProduct?id=${id}`
          `https://vh-apis.vercel.app/deleteProduct?id=${id}`
        );

        fetchProducts();
      } catch (err) {
        setError(err.message);
      }
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await fetch(
        // "https://vh-apis.onrender.com/orders/getOrders"
        "https://vh-apis.vercel.app/orders/getOrders"
        // "http://127.0.0.1:3005/orders/getOrders"
      );
      const data = await response.json();
      console.log(data.orders);
      setOrders(data.orders);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  if (isLoading) {
    return <div className="text-3xl font-bold">Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="container mx-auto py-8 overflow-scroll">
      <h1 className="text-3xl font-bold mb-6">ORDERS</h1>
      <div className="overflow-x-auto">
        <table
          className="w-full table-auto border-separate border-spacing-y-3 border-black"
          style={{ tableLayout: "fixed" }}
        >
          <thead>
            <tr className="bg-gray-200">
              <th className="px-4 py-2">Thumbnail</th>
              <th className="px-4 py-2">Order Id</th>
              <th className="px-4 py-2">Customer Details</th>
              <th className="px-4 py-2">Amount</th>
              <th className="px-4 py-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order) => (
              <tr
                key={order._id}
                className="border-b hover:bg-cyan-50 cursor-pointer"
              >
                <td className="px-4 py-2" style={{ width: "20%" }}>
                  <div className="w-full text-center flex flex-col item-center">
                    <img
                      src={order.productDetails.thumbnail}
                      alt={order.productDetails.title}
                      className="h-40 w-fit object-cover self-center"
                    />
                  </div>
                </td>

                <td className="px-4 py-2" style={{ width: "20%" }}>
                  <div className="w-full text-center flex flex-col item-center">
                    <div className="flex flex-col gap-1">
                      <span className="">cf order id</span>
                      <span className="font-semibold">
                        {order.orderDetails.cf_order_id}
                      </span>
                    </div>
                    <div className="flex flex-col gap-1">
                      <span className="">vh order id</span>
                      <span className="font-semibold w-40 overflow-hidden hover:overflow-visible">
                        {order.orderDetails.order_id}
                      </span>
                    </div>
                  </div>
                </td>

                <td className="px-4 py-2" style={{ width: "20%" }}>
                  <div className="w-full text-center flex flex-col item-center">
                    <div className="flex flex-col gap-1">
                      <span className="">Name</span>
                      <span className="font-semibold">
                        {order.orderDetails.customer_details.customer_name ||
                          "NULL"}
                      </span>
                    </div>
                    <div className="flex flex-col gap-1">
                      <span className="">Phone</span>
                      <span className="font-semibold overflow-hidden hover:overflow-visible">
                        {order.orderDetails.customer_details.customer_phone}
                      </span>
                    </div>
                  </div>
                </td>

                <td className="px-4 py-2" style={{ width: "20%" }}>
                  <div className="w-full text-center flex flex-col item-center">
                    <div className="flex flex-col gap-1">
                      {/* <span className=''>Name</span> */}
                      <span className="font-semibold">
                        ₹ {order.orderDetails.order_amount || "NULL"}
                      </span>
                    </div>
                    {/* <div className='flex flex-col gap-1'>
                            <span className=''>Phone</span>
                            <span className='font-semibold w-40 overflow-hidden hover:overflow-visible'>{order.orderDetails.customer_details.customer_phone}</span>
                        </div> */}
                  </div>
                </td>

                <td className="px-4 py-2" style={{ width: "20%" }}>
                  <div className="w-full text-center flex flex-col item-center">
                    <Link to={`/orderDetails?id=${order._id}`}>
                      <div className="flex flex-col gap-1">
                        {/* <span className=''>Name</span> */}
                        <button className="bg-black text-white rounded-md py-2">
                          View details
                        </button>
                      </div>
                    </Link>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Orders;
